import { isApp, isIOS, isISOApp } from "@utils/helpers";
import deepCopy from "@utils/deepCopy";
import dynamic from "next/dynamic";
// import RenderPart from "./RenderPart";
const RenderPart = dynamic(() => import("./RenderPart"));
const RenderPartWithImageLoad = dynamic(() =>
    import("./RenderPart").then((v) => v.RenderPartWithImageLoad),
);

export const parseCMSJson = (obj: any) => {
    if (typeof obj === "string") {
        return obj;
    }
    const returnObj = obj
        ?.map(function (o: any, key1: number) {
            const outerId = `id="rich-text-text-container-${key1}"`;

            if (o.type === "p") {
                const elem = o.children
                    .map(function (o: any, key2: number) {
                        let styleArr = "";
                        if (o.fontSize) {
                            styleArr += `fontSize: ${getPxToRem(
                                Number(o.fontSize.replace("px", "")),
                            )}; `;
                        }
                        if (o.fontWeight) {
                            styleArr += `fontWeight: ${o.fontWeight}; `;
                        }
                        if (o.color) {
                            styleArr += `color: ${o.color}; `;
                        }
                        if (o.filter) {
                            styleArr += `filter: ${o.filter}; `;
                        }
                        const style = styleArr ? ` style="${styleArr}"` : "";
                        const id = `id="rich-text-rendering-${key1}-${key2}"`;

                        if (o.bold) return `<b ${id} ${style}>${o.text}</b>`;
                        else if (o.italic)
                            return `<i ${id} ${style}>${o.text}</i>`;
                        else if (o.underline)
                            return `<u ${id} ${style}>${o.text}</u>`;
                        else if (o.type === "mention")
                            return o.value && o.value.length > 0
                                ? `@@@@{{${o.value}}}@@@@`
                                : "";

                        return style
                            ? `<span ${id} ${style}>${o.text}</span>`
                            : o.text;
                    })
                    .join(" ");
                const lineHeight = o.lineHeight;
                const indent = o.indent;
                let styleArr = "";
                if (indent) {
                    styleArr += `paddingLeft: ${indent * 25}px; `;
                }
                if (lineHeight) {
                    styleArr += `lineHeight: ${lineHeight}; `;
                }
                const style = styleArr ? ` style="${styleArr}"` : "";
                return style ? `<div ${outerId} ${style}>${elem}</div>` : elem;
            } else if (o.type === "img") {
                return `<image=\"${o.url}\">`;
            } else if (o.type === "latex") {
                return o.latex;
            } else if (o.type === "video") {
                return `<video=\"${o.video}\">`;
            } else if (o.type === "audio") {
                return `<audio=\"${o.audio}\">`;
            }
        })
        .join("\n");

    return returnObj;
};

const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

const cleanLatex = (text: string, delimiter = "$") => {
    const split = text.split(delimiter);
    let returnString = "";
    split.map((item, index) => {
        if (index % 2 === 0) {
            returnString += item;
        } else {
            returnString += replaceAll(
                `${delimiter}${item}${delimiter}`,
                "\n",
                " ",
            );
        }
    });
    return returnString;
};

const getParts = (text: any, displayType: string) => {
    const parts: { type: string; val: any }[] = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = cleanLatex(txt);
    txt = cleanLatex(txt, "$$");
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    const splitted = txt.split("\n");

    for (let splt of splitted) {
        splt = splt.trim();
        if (splt.includes("<image")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<image=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                displayType === "card" &&
                    prts.push({
                        type: "img",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else if (splt.includes("<video")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<video=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                prts.push({
                    type: "video",
                    val: currentPart.substr(
                        imgIndex + 8,
                        endIndex - imgIndex - 8,
                    ),
                });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else if (splt.includes("<audio")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<audio=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                prts.push({
                    type: "audio",
                    val: currentPart.substr(
                        imgIndex + 8,
                        endIndex - imgIndex - 8,
                    ),
                });
                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else {
            const splitArr = splt.split("");
            const isLatex =
                splitArr[0] === "$" && splitArr[splitArr.length - 1] === "$";

            if (isLatex) {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            } else {
                if (!splt)
                    parts.push({
                        type: "break",
                        val: splt,
                    });
                else
                    parts.push({
                        type: "latex_div",
                        val: splt,
                    });
            }
        }
    }
    return parts;
};

export const renderer = (json: any, props = {}) => {
    const text = parseCMSJson(json);
    return (
        text &&
        getParts(replaceFracToDFrac(text), "card").map((part, pIndex) => {
            return (
                <RenderPart
                    part={
                        typeof part?.val == 'object' ?
                            { ...part, val: part?.val?.map(i => ({ ...(json.find(j => j.type == i.type && j[j.type] == i.val) ?? {}), ...i })) }
                            : part
                    }
                    key={"_" + pIndex}
                    {...props}
                    pIndex={pIndex}
                />
            )
        })
    );
};

export const renderWithImageLoad = (json: any, props = {}) => {
    const text = parseCMSJson(json);
    return (
        text &&
        getParts(replaceFracToDFrac(text), "card").map((part, pIndex) => (
            <RenderPartWithImageLoad
                part={part}
                key={"_" + pIndex}
                {...props}
                pIndex={pIndex}
            />
        ))
    );
};

const DASH_DEVICE_WIDTH = 412;
const DASH_DEVICE_HEIGHT = Math.round((DASH_DEVICE_WIDTH * 16) / 9);
export const getPercentTopLeft = (position: {
    top: number;
    left: number;
}): {
    top: string;
    left: string;
} => {
    let isios =
        isIOS() &&
        !isISOApp() &&
        !(document?.fullscreenEnabled || document?.webkitFullscreenEnabled);
    const div = document.getElementById("story-parent");
    const width = div?.clientWidth;
    const height = div?.clientHeight;
    const { top = 0, left = 0 } = position;
    const aspectRatio = DASH_DEVICE_WIDTH / DASH_DEVICE_HEIGHT;
    let newWidth;
    let newHeight;
    let newTop;
    let newLeft;
    if (isios) {
        newHeight = height;
        newWidth = Math.round(newHeight * aspectRatio);
        const widthDifference = newWidth - width;
        let baseLeft = (left / DASH_DEVICE_WIDTH) * newWidth;
        newLeft = baseLeft - widthDifference / 2;
        newLeft = `${newLeft}px`;
        newTop = `${(top / DASH_DEVICE_HEIGHT) * 100}%`;
    } else {
        newWidth = width;
        newHeight = Math.round(newWidth / aspectRatio);
        const heightDifference = newHeight - height;
        let baseTop = (top / DASH_DEVICE_HEIGHT) * newHeight;
        newTop = baseTop - heightDifference / 2;
        newTop = `${newTop}px`;
        newLeft = `${(left / DASH_DEVICE_WIDTH) * 100}%`;
    }

    const retPosition = {
        top: newTop,
        left: newLeft,
    };

    return {
        ...position,
        ...retPosition,
    };
};

export const getPercentHeightWidth = (size: {
    height: number;
    width: number;
}): {
    height: number;
    width: number;
} => {
    let isios =
        isIOS() &&
        !isISOApp() &&
        !(document?.fullscreenEnabled || document?.webkitFullscreenEnabled);
    const div = document.getElementById("story-parent");
    const divWidth = div?.clientWidth;
    const divHeight = div?.clientHeight;
    const { height = 0, width = 0 } = size;

    const scaleFactor = isios
        ? divHeight / DASH_DEVICE_HEIGHT
        : divWidth / DASH_DEVICE_WIDTH;
    const newWidth = scaleFactor * width;
    const newHeight = scaleFactor * height;
    const retSize = {
        height: newHeight,
        width: newWidth,
    };

    return {
        ...size,
        ...retSize,
    };
};

export const getPxToRem = (font: any) => {
    return `${(font || 16) / 16}rem`;
};

export function replaceFracToDFrac(json: any) {
    function replace(obj: any): any {
        if (Array.isArray(obj)) {
            // If obj is an array
            return obj.map((item) => replace(item)); // Recursively call replace for each item in the array
        } else if (typeof obj === "object" && obj !== null) {
            // If obj is an object
            const replacedObj: any = {};
            Object.keys(obj).forEach((key) => {
                if (typeof obj[key] === "string") {
                    // If the value is a string, perform replacement
                    const replacedValue = replaceMatch(obj[key]);
                    replacedObj[key] = replacedValue;
                } else {
                    // Recursively call replace for nested objects or arrays
                    replacedObj[key] = replace(obj[key]);
                }
            });
            return replacedObj;
        } else if (typeof obj === "string") {
            // If obj is a string
            return replaceMatch(obj);
        } else {
            // Other types (e.g., numbers, booleans) remain unchanged
            return obj;
        }
    }

    function replaceMatch(text: string) {
        const regex = /\\frac/g;
        return text.replaceAll(regex, "\\dfrac");
    }

    return replace(deepCopy(json));
}

export const getMaxDuration = (
    mediaArray: { url: any; type: any }[],
    storyDuration: number,
    duration_type: string,
    assetsDuration: { [x: string]: any },
) => {
    if (!duration_type && storyDuration != 0) return storyDuration;
    const promises = mediaArray.map(({ url, type }) => {
        if (assetsDuration[url]) return Math.ceil(assetsDuration[url]);
        return new Promise((resolve) => {
            const mediaElement = document.createElement(
                type === "VIDEO" ? "video" : "audio",
            );

            mediaElement.addEventListener("loadedmetadata", () => {
                assetsDuration[url] = mediaElement.duration;
                resolve(Math.ceil(mediaElement.duration));
            });

            mediaElement.addEventListener("error", () => {
                resolve(-99);
            });

            mediaElement.src = url;
        });
    });
    return Promise.all(promises).then((durations: any = []) => {
        if (["maximum"].includes(duration_type)) durations.push(storyDuration);
        if (!durations?.length) return storyDuration;
        return Math.max(...durations);
    });
};
