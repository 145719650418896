import React, { useState } from "react";
import { convertUrlsToCdnAndImgKit } from "./convertURLToImg";
import { captureException } from "@sentry/nextjs";

const imageKitLoader = ({ src, width, height }) => {
    if (src === "undefined" || !src) return src;
    const convertedUrl = convertUrlsToCdnAndImgKit({ url: src, type: "IMG" });
    return `${convertedUrl}?f-auto`;
};

const CustomImage = (imgProps: any) => {
    return <img {...imgProps} />;
    const {
        src,
        width,
        height,
        alt,
        retryLimit = 2,
        fallback,
        NativeImgProps,
        ...props
    } = imgProps;
    const [imageSrc, setImageSrc] = useState(
        imageKitLoader({ src, width, height }),
    );
    const [currentRetry, setCurrentRetry] = useState(0);
    const [isError, setIsError] = useState(false);

    const handleError = (e) => {
        const currentUrl = window.location.href;
        const errorDetails = {
            error: e,
            currentPage: currentUrl,
            imageUrl: src,
            kind: "Image Load Error: Imagekit Parsing error",
        };

        console.log(errorDetails);
        captureException(
            new Error("Image Load Error:: Imagekit Parsing error"),
            {
                extra: {
                    errorDetails,
                    disable_replay: true, // Custom field to signal disabling replay
                },
                tags: { kind: "Image Load Error: Imagekit Parsing error" },
            },
        );
        if (currentRetry < retryLimit) {
            setImageSrc(src); // Reattempt loading the original source
            setCurrentRetry(currentRetry + 1);
        } else {
            setIsError(true);
        }
    };

    return (
        <img
            {...NativeImgProps}
            src={imageSrc}
            alt={alt}
            width={width}
            height={height}
            onError={handleError}
            style={{ display: isError ? "none" : "block" }}
            {...props}
        />
    );
};

export default CustomImage;
