import { captureException } from "@sentry/nextjs";
import axios from "axios";

export const setStudentCookie = (user: any) => {
    const cookieString = `personalized_student=${user}; max-age=31536000; path=/; domain=.mathai.ai;`;
    if (document) document.cookie = cookieString;
};

export const setStudentToStorage = (user: any) => {
    setStudentCookie(user);
    localStorage.setItem("personalized_student", user);
};

export const deleteStudentFromStorage = () => {
    if (document)
        document.cookie =
            "personalized_student=;expires=Thu, 01 Jan 1970 00:00:01 GMT;  path=/; domain=.mathai.ai;";
    localStorage?.removeItem("personalized_student");
};

export const getStudentFromStorage = () => {
    const localUser = localStorage?.getItem("personalized_student");
    const cookieUser = getCookie("personalized_student");

    if (cookieUser) localStorage?.setItem("personalized_student", cookieUser);
    else if (localUser) setStudentCookie(localUser);

    return cookieUser || localUser;
};

export const checkOrSetCookie = async () => {
    const user = localStorage.getItem("personalized_student");

    if (user) {
        try {
            const userCookie = getCookie("personalized_student");
            if (userCookie) return;
            setStudentCookie(user);
        } catch (error) {
            console.error("Error setting cookie:", error);
        }
    }
};
export const setAccessTokentoCookie = async () => {
    const user = getStudentFromStorage();
    if (user) {
        try {
            const { mobile: phoneNumber } = JSON.parse(user);
            if (phoneNumber) {
                const accessToken = await generateSessionToken(phoneNumber);
                if (!accessToken) {
                    console.log("token generation failed as new user");
                    return;
                }
                document.cookie = `accesstoken=${accessToken}; domain=.mathai.ai;`;
            }
        } catch (error) {
            console.error("Error setting cookie:", error);
        }
    }
};
export const checkOrSetUser = () => {
    const user = localStorage.getItem("personalized_student");
    if (user) return;
    const userCookie = getCookie("personalized_student");
    if (userCookie) {
        localStorage.setItem("personalized_student", userCookie);
    }
};

function getCookie(name: string) {
    const value = `; ${document?.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(";")?.shift();
    return null;
}

const generateSessionToken = async (phoneNumber: string) => {
    try {
        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_SUPERTOKEN_API_BACKEND_DOMAIN}/supertokens/generate`,
            { phoneNumber },
        );

        const accessToken = response?.data?.sessionToken?.accessToken;

        return accessToken;
    } catch (error) {
        console.error("Error generating token:", error);
        captureException(error);
        return null;
    }
};
